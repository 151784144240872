import './quote.scss';

import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/scss';

class Quote {
    constructor ($element) {
        this.$quote = $element;
        this.isDesktopBp = window.matchMedia('(min-width: 1024px)');
        this.$sliderContainer = this.$quote.querySelector('[data-quote="slider"]');
        this.init();
    }

    init () {
        if (this.$sliderContainer) {
            this.initSlider();
        }

        window.resizeHandler.customFunctions.push(() => {
            this.initSlider();
        });
    }

    initSlider () {
        this.slider = new Swiper(this.$sliderContainer, {
            modules: [Navigation, Pagination],
            slidesPerView: 'auto',
            speed: 500,
            autoHeight: false,
            navigation: {
                nextEl: this.$quote.querySelector('.slider-button-next'),
                prevEl: this.$quote.querySelector('.slider-button-prev')
            },
            pagination: {
                el: this.$quote.querySelector('.slider-pagination'),
                type: 'fraction',
                formatFractionCurrent: function (number) {
                    return ('0' + number).slice(-2);
                },
                formatFractionTotal: function (number) {
                    return ('0' + number).slice(-2);
                }
            },
            on: {
                afterInit: () => {
                    if (this.$quote.querySelector('.slider-button-next').classList.contains('swiper-button-disabled') & this.$quote.querySelector('.slider-button-prev').classList.contains('swiper-button-disabled')) {
                        this.$quote.querySelector('.quote__slider-navigation').style.display = 'none';
                    } else {
                        this.$quote.querySelector('.quote__slider-navigation').style.display = 'flex';
                    }
                }
            }
        });
    }
}

export { Quote };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $quotes = $context.querySelectorAll('[data-quote="root"]');

        for (let i = 0; i < $quotes.length; i++) {
            const $quote = new Quote($quotes[i]);
            $quote.init();
        }
    }
});
